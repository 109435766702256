/* eslint-disable class-methods-use-this */
import SimpleReactRouter from './simple-react-router'
import Home from './pages/Home';
import Sestina from './pages/Sestina';
import Dice from './pages/Dice';
import About from './pages/About';
import Help from './pages/Help';

export default class Router extends SimpleReactRouter {
  routes(map) {
    map('/', Home)
    map('/about', About)
    map('/help', Help)
    map('/dice', Dice)
    map('/sestina', Sestina)
    map('/:path*', Home) // catchall route
  }
}
