/* eslint-disable react/destructuring-assignment, no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { Button } from 'grommet';
import RollableDie from '../components/RollableDie';
import Page from '../components/Page';
import { Content } from '../shared-styled';

const getRandomDifferent = (max, indexToExclude) => {
  let rand = null;
  while (rand === null || rand === indexToExclude) {
    rand = Math.round(Math.random() * (max - 1));
  }
  return rand;
}

const DicePage = styled(Content)`
  .rollButton {
    margin-top: 10px;
  }
`;

const DiceRow = styled.div`
  display: flex;
  flex-direction: row;
  .diceWrap {
    padding: 20px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    .diceWrap {
      padding: 10px;
    }
  }
  @media (min-width: 768px) and (max-width: 1200px) {

  }
  @media (min-width: 1200px) {

  }
`;

const actions = [
  'Fire Breaks Out',
  'Lost Item Found',
  'Creature Attack',
  'Violent Weather',
  'Betrayal Revealed',
  'Rival Returns',
];

const upsets = [
  'Trap Sprung',
  'Weapon Malfunction',
  'Creature Attack',
  'Violent Weather',
  'Betrayal Revealed',
  'Rival Returns',
];

const changes = [
  'Change Location',
  'Lost Item Found',
  'Creature Attack',
  'Violent Weather',
  'Betrayal Revealed',
  'Rival Returns',
];

class Dice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prompts: actions,
      diceARolling: false,
      diceASide: 0,
      diceBRolling: false,
      diceBSide: 0,
      diceCRolling: false,
      diceCSide: 0,
    }
  }

  rollDice = (diceName) => {
    const side = this.state[`${diceName}Side`];
    this.setState({ [`${diceName}Rolling`]: true });

    setTimeout(() => {
      const roll = getRandomDifferent(6, side);
      this.setState({ [`${diceName}Rolling`]: false, [`${diceName}Side`]: roll })
    }, 750);
  }

  rollAllDice = () => {
    this.rollDice('diceA');
    this.rollDice('diceB');
    this.rollDice('diceC');
  }

  render() {
    const {
      prompts,
      diceARolling,
      diceASide,
      diceBRolling,
      diceBSide,
      diceCRolling,
      diceCSide,
    } = this.state;
    return (
      <Page>
        <DicePage>
          <h1>Story Dice</h1>
          <Button
            primary
            size="large"
            className="rollButton"
            onClick={this.rollAllDice}
            label="Roll All"
          />
          <DiceRow>
            <div className="diceWrap">
              <RollableDie
                sides={prompts}
                name="diceA"
                rollMe={this.rollDice}
                isRolling={diceARolling}
                sideShowing={diceASide}
                pickDifferent
                standalone={false}
                color="#87293D"
              />
            </div>
            <div className="diceWrap">
              <RollableDie
                sides={prompts}
                name="diceB"
                rollMe={this.rollDice}
                isRolling={diceBRolling}
                sideShowing={diceBSide}
                pickDifferent
                standalone={false}
                color="#1E5B4E"
              />
            </div>
            <div className="diceWrap">
              <RollableDie
                sides={prompts}
                name="diceC"
                rollMe={this.rollDice}
                isRolling={diceCRolling}
                sideShowing={diceCSide}
                pickDifferent
                standalone={false}
              />
            </div>
          </DiceRow>
        </DicePage>
      </Page>
    );
  }
}

export default Dice;
