/* eslint-disable no-unused-vars, max-len, react/destructuring-assignment, react/no-array-index-key */
import React, { Component } from 'react';
import styled from 'styled-components';
import { Paragraph } from 'grommet';
import { Col, Row } from 'react-styled-flexboxgrid';
import { Wrap } from '../shared-styled';
import Page from '../components/Page';

const SestinaWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  .topPanel {
    width: 90%;
    p {
      max-width: 100%;
    }
  }
  input {
    margin: 4px 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    border-bottom: 1px dashed ${p => p.theme.secondary12};
    padding: 4px;
    &:active, &:focus {
      border: 0;
      outline: 0;
      box-shadow: none;
      background-color: rgba(255, 255, 255, 0.2);
      border-bottom: 1px solid ${p => p.theme.complement2};
    }
    ::placeholder {
      color: ${p => p.theme.secondary20};
    };
  }
  .heroWordsInput {
    display: flex;
    flex-direction: column;
    background-color: ${p => p.theme.secondary25};
    padding: 6px;
    border-radius: 6px;
  }
  .poemInput {
    width: 90%;
    box-sizing: border-box;
    .stanzaWrap {
      width: 100%;
      margin: 14px 0;
    }
    .poemLine {
      width: 100%;
      max-width: 100%;
      margin: 3px 0;
      border-radius: 6px;
      &:nth-child(even) {
        background-color: ${p => p.theme.primary0};
      }
      &:nth-child(odd) {
        background-color: ${p => p.theme.primary1};
      }
      width: 100%;
      max-width: 100%;
      vertical-align: middle;
      input {
        width: 100%;
        text-align: right;
      }
      .heroWordOutput {
        display: flex;
        align-items: center;
        padding-left: 0px;
        font-size: 14px;
      }
    }
  }
  @media (max-width: 768px) {
    .topPanel {
      flex-direction: column-reverse;
    }
  }
  @media (min-width: 768px) and (max-width: 1200px) {

  }
  @media (min-width: 1200px) {

  }
`;

export default class Sestina extends Component {
  constructor(props) {
    super(props);
    this.heroArray = 'ABCDEF'.split('');
    this.heroPattern = [
      'ABCDEF',
      'FAEBDC',
      'CFDABE',
      'ECBFAD',
      'DEACFB',
      'BDFECA',
    ].map((pat) => pat.split(''));
    const stanzaState = {};
    this.heroPattern.forEach((stanza, i) => {
      const stz = `stanza${i}`;
      stanza.forEach((line) => {
        stanzaState[`${stz}${line}`] = '';
      });
    });
    this.state = {
      heroA: '',
      heroB: '',
      heroC: '',
      heroD: '',
      heroE: '',
      heroF: '',
      ...stanzaState,
    }
  }

  render() {
    const {
      heroA,
      heroB,
      heroC,
      heroD,
      heroE,
      heroF,
    } = this.state;

    return (
      <Page>
        <SestinaWrap>
          <h1>Sestina Wizard</h1>
          <Row className="topPanel">
            <Col xs={12} sm={4} lg={4} xl={4}>
              <div className="heroWordsInput">
                {
                  this.heroArray.map((hero) => {
                    const heroVal = `hero${hero}`;
                    return (
                      <input
                        type="text"
                        placeholder={`Hero Word ${hero}`}
                        key={`inputHero${hero}`}
                        value={this.state[heroVal]}
                        onChange={({ target: { value } }) => this.setState({ [`hero${hero}`]: value })}
                      />
                    );
                  })
                }
              </div>
            </Col>
            <Col xs={12} sm={8} lg={8} xl={8}>
              <Paragraph>
                A sestina (Italian: sestina, from sesto, sixth; also known as sestine, sextine, sextain) is a fixed verse form consisting of six stanzas of six lines each, normally followed by a three-line envoi. The words that end each line of the first stanza are used as line endings in each of the following stanzas, rotated in a set pattern.
              </Paragraph>
            </Col>
          </Row>
          <div className="poemInput">
            {
              this.heroPattern.map((stanza, i) => (
                <div className="stanzaWrap" key={`stanza${i}`}>
                  {
                    stanza.map((verse, line) => {
                      const heroWordState = this.state[`hero${verse}`];
                      const heroWordFilled = heroWordState !== '';
                      return (
                        <Row className="poemLine" key={`line${i}${verse}`}>
                          <Col xs={12} sm={10} lg={10} xl={10}>
                            <input
                              type="text"
                              value={this.state[`stanza${i}${verse}`]}
                              placeholder={`Stanza ${i} Line ${line}`}
                              onChange={({ target: { value } }) => this.setState({ [`stanza${i}${verse}`]: value })}
                            />
                          </Col>
                          <Col className="heroWordOutput" xs={12} sm={2} lg={2} xl={2}>
                            <div>{heroWordFilled ? heroWordState : `Hero ${verse}`}</div>
                          </Col>
                        </Row>
                      )
                    })
                  }
                </div>
              ))
            }
          </div>
        </SestinaWrap>
      </Page>
    );
  }
}
