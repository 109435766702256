/* eslint-disable react/no-array-index-key, react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
// import './Die.css';

const rotation = keyframes`
  from {
    transform: rotateX(0deg) rotateY(0deg);
  }
  to {
    transform: rotateX(720deg) rotateY(720deg);
  }
`;

const DieWrap = styled.div`
  position: relative;
  perspective: 1000px;
  width: 200px;
  height: 200px;
  cursor: pointer;
  .theDice {
    position: absolute;
    transition: transform .25s;
    transform-style: preserve-3d;
    transform: ${p => p.transform};
    width: 100%;
    height: 100%;
    background-color: ${p => p.color || '#512E77'};
    color: #FFFFFF;
    user-select: none;
  }

  .theDice.rolling {
    animation: ${rotation} 1s linear;
  }

  .theDice .side {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    width: 196px;
    height: 196px;
    background-color: inherit;
    border: 2px solid #000000;
    border-radius: 12px;
    font-size: 40px;
    line-height: 44px;
    font-weight: bold;
  }

  .theDice.option-translucent {
    background-color: rgba(213, 0, 0, .80);
  }
  .d6 .side:nth-child(1) {
    transform: rotateY(0deg) translateZ(98px);
  }
  .d6 .side:nth-child(2) {
    transform: rotateX(180deg) translateZ(98px);
  }
  .d6 .side:nth-child(3) {
    transform: rotateY(90deg) translateZ(98px);
  }
  .d6 .side:nth-child(4) {
    transform: rotateY(-90deg) translateZ(98px);
  }
  .d6 .side:nth-child(5) {
    transform: rotateX(90deg) translateZ(98px);
  }
  .d6 .side:nth-child(6) {
    transform: rotateX(-90deg) translateZ(98px);
  }

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
    .theDice {
      transform: translateZ(-50px);
    }
    .theDice .side {
      width: 98px;
      height: 98px;
      font-size: 16px;
      line-height: 20px;
      border-radius: 12px;
    }
    .d6 .side:nth-child(1) {
      transform: rotateY(0deg) translateZ(50px);
    }
    .d6 .side:nth-child(2) {
      transform: rotateX(180deg) translateZ(50px);
    }
    .d6 .side:nth-child(3) {
      transform: rotateY(90deg) translateZ(50px);
    }
    .d6 .side:nth-child(4) {
      transform: rotateY(-90deg) translateZ(50px);
    }
    .d6 .side:nth-child(5) {
      transform: rotateX(90deg) translateZ(50px);
    }
    .d6 .side:nth-child(6) {
      transform: rotateX(-90deg) translateZ(50px);
    }
  }
  @media (min-width: 768px) and (max-width: 1200px) {

  }
  @media (min-width: 1200px) {

  }
`;

const getRandomDifferent = (max, indexToExclude) => {
  let rand = null;
  while (rand === null || rand === indexToExclude) {
    rand = Math.round(Math.random() * (max - 1));
  }
  return rand;
}

class Die extends React.Component {
  constructor(props) {
    super(props);
    this.sides = [
      'translateZ(-100px) rotateY(0deg)',
      'translateZ(-100px) rotateX(-180deg)',
      'translateZ(-100px) rotateY(-90deg)',
      'translateZ(-100px) rotateY(90deg)',
      'translateZ(-100px) rotateX(-90deg)',
      'translateZ(-100px) rotateX(90deg)',
    ];
    this.state = {
      rolling: props.isRolling,
      side: props.sideShowing,
    };
  }

  handleRoll = (name, currentState) => {
    const { rollMe, standalone, pickDifferent } = this.props;
    if (standalone) {
      const { side } = this.state;
      this.setState({ rolling: true });
      setTimeout(() => {
        const roll = pickDifferent ? getRandomDifferent(6, side) : Math.floor(Math.random() * 6);
        this.setState({ side: roll, rolling: false });
      }, 750);
    } else {
      rollMe(name, currentState);
    }
  }

  render() {
    const {
      sideShowing,
      sides,
      isRolling,
      name,
      color,
      size,
      standalone,
    } = this.props;
    let { rolling, side } = this.state;
    if (!standalone) {
      rolling = isRolling;
      side = sideShowing;
    }
    const divs = this.sides.map((s, index) => (
      <div key={`side${index}`} className="side">{sides[index]}</div>
    ));
    return (
      <DieWrap
        className="die-container"
        onClick={() => this.handleRoll(name, { side, rolling })}
        transform={this.sides[side]}
        color={color}
        size={size}
      >
        <div className={`theDice d${this.sides.length} ${rolling && 'rolling'}`}>
          { divs }
        </div>
      </DieWrap>
    );
  }
}

Die.propTypes = {
  sides: PropTypes.array,
  sideShowing: PropTypes.number,
  isRolling: PropTypes.bool,
  standalone: PropTypes.bool,
  pickDifferent: PropTypes.bool,
  rollMe: PropTypes.func,
  name: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
};

Die.defaultProps = {
  rollMe: () => {},
  isRolling: false,
  standalone: true,
  pickDifferent: false,
  sides: [1, 2, 3, 4, 5, 6],
  sideShowing: 0,
  color: '#512E77',
  size: '200px',
  name: 'dice',
};

export default Die;
