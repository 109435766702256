import React from 'react';
import ReactDOM from 'react-dom';
import { Grommet } from 'grommet';
import { createGlobalStyle } from 'styled-components';
import ReactGA from 'react-ga';

import Router from './Router';
import theme from './theme';
import reportWebVitals from './reportWebVitals';

ReactGA.initialize('UA-54271044-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const GlobalStyle = createGlobalStyle`
  #root {
    width: 100%;
    height: 100%;
    > div {
      width: 100%;
      height: 100%;
    }
  }
  html, body {
    width: 100%;
    min-height: 100vh;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
  }
  body {
    box-sizing: border-box;
    width: 100%;
    font-family: ${theme.font};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

ReactDOM.render(
  <>
    <GlobalStyle />
    <Grommet theme={theme}>
      <Router />
    </Grommet>
  </>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
