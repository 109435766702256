import styled from 'styled-components';
import theme from './theme';

const { logoWidth } = theme;

export const size = {
  xs: '320px',
  sm: '768px',
  lg: '1200px',
};

export const PageWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${p => p.theme.primary4};
  height: 100%;
  min-height: 100%;
  * {
    box-sizing: border-box;
  }
  .main {
    .logoImg {
      z-index: 4;
      box-shadow: 0px 5px 10px 3px rgba(0,0,0,0.75);
      border-radius: 0 0 60% 60%;
    }
    h1 {
      margin-block-start: 0.67em;
      margin-block-end: 0.2em;
    }
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      background-color: ${p => p.theme.primary4};
      width: 5px;

    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: ${p => p.theme.secondary21};
    }
     
    ::-webkit-scrollbar-thumb {
      background-color: ${p => p.theme.primary1};
      outline: 1px solid ${p => p.theme.primary2};
    }
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    background-color: ${p => p.theme.primary2};

    @media (max-width: 768px) {
      width: 100%;
      max-height: 100%;
      .logoImg {
        width: ${logoWidth.sm};
      }
    }
    @media (min-width: 768px) and (max-width: 1200px) {
      width: 90%;
      .logoImg {
        width: ${logoWidth.xl};
      }
    }
    @media (min-width: 1200px) {
      .add-to-home-banner {
        display: none;
      }
      width: 960px;
      min-width: 360px;
      .logoImg {
        width: ${logoWidth.xl};
      }
    }
  }
`;

export const Wrap = styled(PageWrap)`
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background-color: ${p => p.theme.primary2};
  .actionButton {
    background-color: transparent;
    border: none;
    outline: none;
  }
  .promptWrap {
    position: relative;
    width: 100%;
    .promptTools {
      position: absolute;
      bottom: 18px;
      right: 36px;
      cursor: pointer;
      svg {
        fill: ${p => p.theme.primary0};
        &:hover {
          fill: ${p => p.theme.secondary12};
        }
      }
    }
  }

  .showPrompt {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    justify-content: center;
    min-width: 80%;
    border-radius: 10px;
    background-color: ${p => p.theme.primary1};
    .promptOutput {
      span, button {
        font-size: 18px;
        margin: 0 3px;
      }
      button {
        border: 0;
        border-radius: 4px;
        background-color: rgba(173, 216, 230, 0.5);
        white-space: nowrap;
        &:hover {
          background-color: rgba(173, 216, 230, 1);

        }
      }
    }
    .editWrap {
      width: 100%;
      box-sizing: border-box;
      textarea {
        resize: none;
        box-sizing: border-box;
        width: 100%;
        padding: 8px 12px;
        border-radius: 6px;
        background-color: ${p => p.theme.primary4};
        color: ${p => p.theme.white};
        font-family: ${p => p.theme.font};
      }
      .invalidElements {
        font-size: 12px;
      }
      .availableElements {
        background-color: ${p => p.theme.secondary21};
        border-radius: 10px;
        button {
          background-color: ${p => p.theme.secondary22};
          font-size: 12px;
          padding: 4px 6px;
          margin: 4px;
          border-radius: 14px;
        }
      }
      .doneButton {
        margin-top: 6px;
      }
    }
  }
  .help {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      width: 100%;
      font-size: 14px;
      text-align: center;
      line-height: 20px;
      color: #444;
      margin-block-start: 8px;
      margin-block-end: 8px;
      svg {
        vertical-align: middle;
      }
    }
  }
  .savedPrompts {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    > div {
      padding: 8px;
      margin: 8px 16px;
      border-radius: 6px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &:nth-child(even) {
        background-color: ${p => p.theme.primary1};
      }
      &:nth-child(odd) {
        background-color: ${p => p.theme.secondary21};
      }
      button {
        svg {
          fill: ${p => p.theme.primary2};
        }
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    max-height: 100%;
    .logoImg {
      z-index: 4;
      width: ${logoWidth};
    }
    .generateButton {
      min-height: 44px;
    }
    .editWrap {
      textarea {
        min-height: 100px;
      }
    }
    .promptWrap {
      padding: 10px;
      .promptTools {
        position: absolute;
        bottom: 18px;
        right: 18px;
      }
      .showPrompt {
        padding: 12px;
        min-height: 180px;
        padding-bottom: 32px;
      }
    }
    .savedPrompts {
      font-size: 14px;
      line-height: 16px;
      margin-top: 2px;
      > div {
        margin: 4px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    width: 700px;
    .promptWrap {
      padding: 15px 30px;
      .showPrompt {
        padding: 15px 15px;
        min-height: 80px;
      }
    }
    .logoImg {
      z-index: 4;
      width: ${logoWidth.lg};
    }
    .savedPrompts {
      font-size: 16px;
      margin-top: 16px;
    }
  }
  @media (min-width: 1200px) {
    .add-to-home-banner {
      display: none;
    }
    width: 960px;
    min-width: 360px;
    .logoImg {
      z-index: 4;
      width: ${logoWidth.xl};
    }
    .promptWrap {
      padding: 15px 30px;
      .showPrompt {
        padding: 15px 15px;
        min-height: 80px;
      }
    }
    .savedPrompts {
      font-size: 16px;
      margin-top: 4px;
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

/*

  @media (max-width: 768px) {

  }
  @media (min-width: 768px) and (max-width: 1200px) {

  }
  @media (min-width: 1200px) {

  }

*/
