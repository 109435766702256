import React from 'react';
import styled from 'styled-components';
import { Paragraph } from 'grommet';
import Page from '../components/Page';
import { Content } from '../shared-styled';

const HelpPage = styled(Content)`
  
`;

const Help = () => (
  <Page>
    <HelpPage>
      <h1>Help</h1>
      <Paragraph>
        Lorem Ipsum
      </Paragraph>
    </HelpPage>
  </Page>
);

export default Help;
