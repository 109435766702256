/* eslint-disable  max-len, react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MenuIcon } from '../icons';
import { Link } from '../simple-react-router';
import { PageWrap } from '../shared-styled';
import logo from '../img/logo.png';
import { version } from '../package.alias.json';

const MenuWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 30px;
  background-color: ${p => p.theme.primary3};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${p => p.theme.white};
  font-size: 12px;
  a {
    color: ${p => p.theme.primary5};
    text-decoration: none;
    &:hover {
      color: ${p => p.theme.secondary12};
      text-decoration: underline;
    }
  }
  .leftSide, .rightSide {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .leftSide > div, .rightSide > div {
    margin: 0 8px;
  }
  .leftSide {
    justify-content: start;
  }
  .rightSide {
    justify-content: End;
  }
  @media (max-width: 768px) {
    height: 36px;
    .mobile {
      button {
        background-color: transparent;
        padding: 0;
        margin: 0 8px;
        border: 0;
        outline: none;
        svg {
          fill: ${p => p.active ? p.theme.secondary12 : p.theme.primary5};
        }
      }
      z-index: 3;
      .menuPopup {
        position: absolute;
        left: 0;
        top: 36px;
        background-color: ${p => p.theme.white};
        width: 80px;
        a {
          color: ${p => p.theme.primary4};
          font-size: 16px;
        }
        > div {
          margin: 0 8px;
        }
      }
    }
    .leftSide, .rightSide {
      display: none;
    }
    svg {
      fill: ${p => p.theme.primary5};
    }
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    .mobile {
      display: none;
    }
  }
  @media (min-width: 1200px) {
    .mobile {
      display: none;
    }
  }
`;

const menuLinks = [
  {
    href: '/',
    key: 'prompts',
    label: 'Prompts',
    align: 'leftSide',
  },
  {
    href: '/dice',
    key: 'dice',
    label: 'Dice',
    align: 'leftSide',
  },
  {
    href: '/sestina',
    key: 'sestina',
    label: 'Sestina',
    align: 'leftSide',
  },
  {
    href: '/help',
    key: 'help',
    label: 'Help',
    align: 'rightSide',
  },
  {
    href: '/about',
    key: 'about',
    label: 'About',
    align: 'rightSide',
  },
]

const Page = ({ children }) => {
  const [showMenu, toggleMenu] = useState(false);
  return (
    <PageWrap>
      <div className="main">
        <MenuWrap className="menu" active={showMenu}>
          <div className="mobile">
            <button type="button" onClick={() => toggleMenu(!showMenu)}>
              <MenuIcon />
            </button>
            {
              showMenu && (
                <div className="menuPopup">
                  {
                    menuLinks.map(({ key, label, href }) => (
                      <div key={key}>
                        <Link href={href}>{ label }</Link>
                      </div>
                    ))
                  }
                </div>
              )
            }
          </div>
          <div className="leftSide">
            {
              menuLinks.filter(({ align }) => align === 'leftSide').map(({ label, key, href }) => (
                <div>
                  <Link key={key} href={href}>{ label }</Link>
                </div>
              ))
            }
          </div>
          <div className="rightSide">
            {
              menuLinks.filter(({ align }) => align === 'rightSide').map(({ label, key, href }) => (
                <div>
                  <Link key={key} href={href}>{ label }</Link>
                </div>
              ))
            }
          </div>
        </MenuWrap>
        <img src={logo} title={`Bored Muse v${version}`} className="logoImg" alt="Bored Muse logo, featuring Clio" />
        <>
          {children}
        </>
      </div>
    </PageWrap>
  );
};
Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Page;
