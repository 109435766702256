const palette = {
  buttonColor: '#7D4CDB',
  primary0: '#505F92',
  primary1: '#AAB3D0',
  primary2: '#7683AD',
  primary3: '#314177',
  primary4: '#18285B',
  primary5: '#ccd3eb',

  secondary10: '#D8B669',
  secondary11: '#FFEECA',
  secondary12: '#FFE3A4',
  secondary13: '#B08B3A',
  secondary14: '#876417',

  secondary20: '#6E4D92',
  secondary21: '#BBA8D0',
  secondary22: '#9074AD',
  secondary23: '#512E77',
  secondary24: '#37155B',
  secondary25: '#A6A8D2',

  complement0: '#D8D169',
  complement1: '#FFFBCA',
  complement2: '#FFF9A4',
  complement3: '#B0A83A',
  complement4: '#877F17',
  white: '#FFFAFA',
};

// eslint-disable-next-line import/prefer-default-export
const theme = {
  ...palette,
  // eslint-disable-next-line max-len
  font: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  colors: {
    primary: '#0070f3',
  },
  checkBox: {
    toggle: {
      background: palette.secondary11,
    },
  },
  global: {
    control: {
      border: {
        radius: '16px',
      },
    },
  },
  button: {
    default: {
      background: {
        opacity: 1,
        color: palette.buttonColor,
      },
    },
    primary: {
      background: {
        opacity: 1,
        color: palette.buttonColor,
      },
    },
    secondary: {
      background: {
        color: palette.primary1,
      },
      color: '#fff',
    },
  },
  logoWidth: {
    sm: '180px',
    lg: '380px',
    xl: '380px',
  },
  breakpoints: {
    xs: '320px',
    sm: '768px',
    lg: '1200px',
  },
  menu: {

  },
  flexboxgrid: {
    gridSize: 12,
    gutterWidth: 1,
    outerMargin: 2,
    mediaQuery: 'only screen',
    container: {
      sm: 46,
      md: 61,
      lg: 76,
    },
    breakpoints: {
      xs: 0,
      sm: 48,
      md: 64,
      lg: 75,
    },
  },
};

export default theme;
