import React from 'react';
import styled from 'styled-components';
import { Paragraph } from 'grommet';
import Page from '../components/Page';
import { Content } from '../shared-styled';

const AboutPage = styled(Content)`
  
`;

const About = () => (
  <Page>
    <AboutPage>
      <h1>About Bored Muse</h1>
      <Paragraph>
        Lorem Ipsum
      </Paragraph>
    </AboutPage>
  </Page>
);

export default About;
