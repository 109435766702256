import React, { Component } from 'react';
import axios from 'axios';
import insertTextAtCursor from 'insert-text-at-cursor';
import { MobileView } from 'react-device-detect';
import { nanoid } from 'nanoid';
import { Button } from 'grommet';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import homeScreenLogo from '../img/logo192.png';
import prompts from '../prompts.json';
import { Wrap } from '../shared-styled';
import Page from '../components/Page';
import AddToHomescreen from '../components/AddToHomeScreen';
import { EditIcon, ClipIcon } from '../icons';

const rand = (maxLimit = 100) => {
  const rando = Math.random() * maxLimit;
  return Math.floor(rando);
}

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promptInput: prompts[0],
      promptData: {},
      elementIndex: {},
      generatedPrompts: [],
      fetching: false,
      disableButtons: {},
      showEdit: false,
      showHelp: true,
      customPrompt: false,
      invalidElementList: [],
      loaded: false,
    }
  }

  componentDidMount() {
    this.getRandom();
  }

  handleAddToHomescreenClick = () => {
    // eslint-disable-next-line no-alert
    alert(`
  1. Open Share menu
  2. Tap on "Add to Home Screen" button`);
  };

  interpolatePromptToArray = (prompt, match, replacements) => {
    const { object } = replacements;
    const split = prompt.split(' ');
    return split.map((word) => {
      const key = nanoid(5);
      if (word.includes('#')) {
        const clean = word.replace('#', '');
        const replaced = object[clean][0];
        object[clean].shift();
        return {
          key,
          text: replaced,
          tag: clean,
          replaceable: true,
        };
      }
      if (word === 'a' || word === 'an') {
        return {
          key,
          text: word,
          tag: 'ana',
        }
      }
      return {
        key,
        text: word,
        tag: 'plain',
      };
    })
  }

  splitAndReturnElements = (prompt) => {
    const split = prompt.split(' ');
    return split.filter((word) => word.includes('#')).map((word) => word.replace('#', ''));
  }

  interpolatePrompt = (prompt, match, object) => {
    const split = prompt.split(' ');
    const hash = {};
    split.forEach((word) => {
      const key = nanoid(5);
      if (word.includes('#')) {
        const clean = word.replace('#', '');
        const replaced = object[clean][0];
        object[clean].shift();
        hash[key] = {
          text: replaced,
          tag: clean,
          replaceable: true,
        };
      } else if (word === 'a' || word === 'an') {
        hash[key] = {
          key,
          text: word,
          tag: 'ana',
        }
      } else {
        hash[key] = {
          key,
          text: word,
          tag: 'plain',
        };
      }
    });
    return hash;
  }

  grammarFix = (prompt) => {
    const promptObj = prompt;
    Object.keys(prompt).forEach((key, i) => {
      const { tag } = prompt[key];
      if (tag === 'ana') {
        const { text } = Object.values(prompt)[i + 1]
        const firstLetterNextWord = text[0];
        const isVowel = (/^[aeiou]$/i).test(firstLetterNextWord);
        if (isVowel) {
          promptObj[key].text = 'an';
        }
      }
    });
    return promptObj;
  }

  replaceMe = async (key, { tag }) => {
    const { promptData, disableButtons } = this.state;
    this.setState({
      disableButtons: {
        ...disableButtons,
        [key]: true,
      },
    })
    await axios.get(`https://us-central1-bored-muse.cloudfunctions.net/getRandomElement?element=${tag}`).then((res) => {
      const { data } = res;
      const replacement = data[tag];
      const currentVal = promptData[key];
      promptData[key] = {
        ...currentVal,
        text: replacement,
      }
      this.setState((prevState) => {
        const { disableButtons: currentlyDisabled } = prevState;
        return {
          promptData,
          disableButtons: {
            ...currentlyDisabled,
            [key]: false,
          },
        }
      });
    });
  }

  getRandom = async (newPrompt) => {
    this.setState({ fetching: true });
    const {
      promptData,
      generatedPrompts,
      customPrompt,
      promptInput,
    } = this.state;
    if (Object.keys(promptData).length !== 0) {
      const savePrompt = Object.values(promptData).map(({ text }) => text).join(' ');
      this.setState({
        generatedPrompts: [
          {
            text: savePrompt,
            key: nanoid(5),
          },
          ...generatedPrompts,
        ],
      })
    }
    // eslint-disable-next-line max-len
    // const prompt = 'Write a #storyType about a #characterDescription #occupation who #action a #characterDescription #occupation in a #placeDescribe #placeType';
    const pick = rand(prompts.length);
    const prompt = newPrompt || customPrompt ? promptInput : prompts[pick];
    const rgx = /#([a-z]+)/ig;
    const match = prompt.match(rgx).map((g) => g.replace('#', ''));
    await axios.post('https://us-central1-bored-muse.cloudfunctions.net/requestRandomElements', {
      elements: match,
    }).then((res) => {
      const { object, elementIndex } = res.data
      const interpolated = this.interpolatePrompt(prompt, match, object)
      const fixGrammar = this.grammarFix(interpolated);
      const promptText = Object.values(interpolated).map(({ text }) => text).join(' ');
      this.setState({
        promptInput: prompt,
        promptData: fixGrammar,
        fetching: false,
        promptText,
        elementIndex,
        loaded: true,
      });
    })
  }

  editPrompt = ({ target: { value } }) => {
    const { elementIndex } = this.state;
    const usedElements = this.splitAndReturnElements(value);
    const invalidElementList = usedElements.filter((el) => !Object.keys(elementIndex).includes(el));
    this.setState({
      promptInput: value,
      invalidElementList,
    });
  }

  insertElement = (val) => {
    const el = document.getElementById('editTextarea');
    insertTextAtCursor(el, `#${val}`);
  }

  doneEditing = () => {
    const { promptInput } = this.state;
    this.getRandom(promptInput);
    this.setState({
      showEdit: false,
      customPrompt: true,
    });
  }

  helpText = () => {
    const { showEdit } = this.state;
    if (showEdit) {
      return (
        <>
          Click the purple element buttons to add them to your prompt.
          <br />
          Make sure there are spaces between each word.
          <br />
          Click Done when you are done editing to start generating prompts.
        </>
      );
    }
    return (
      <>
        Click one of the highlighted words to switch for another random word in the category.
        <br />
        Click the edit button
        {' '}
        <EditIcon />
        {' '}
        to enter a custom prompt.
      </>
    );
  }

  render() {
    const {
      promptData,
      generatedPrompts,
      fetching,
      loaded,
      disableButtons,
      promptText,
      promptInput,
      showEdit,
      showHelp,
      elementIndex,
      invalidElementList,
    } = this.state;
    const invalidElements = invalidElementList.length > 0;
    if (!loaded) {
      return (
        <Page>
          <Wrap>
            <h1>Loading...</h1>
          </Wrap>
        </Page>
      );
    }
    return (
      <Page>
        <Wrap>
          <div className="promptWrap">
            <div className="showPrompt">
              {
                showEdit
                  ? (
                    <div className="editWrap">
                      <textarea
                        value={promptInput}
                        onChange={this.editPrompt}
                        id="editTextarea"
                      />
                      {
                        invalidElements && (
                          <div className="invalidElements">
                            <span>Invalid Elements:</span>
                            {
                              invalidElementList.map((invalidEl) => (
                                <span key={invalidEl}>{ `#${invalidEl}` }</span>
                              ))
                            }
                          </div>
                        )
                      }
                      <div className="availableElements">
                        {
                          Object.keys(elementIndex).map((el) => (
                            <Button
                              secondary
                              key={el}
                              label={`#${el}`}
                              title={`insert #${el}`}
                              onClick={() => this.insertElement(el)}
                            />
                          ))
                        }
                      </div>
                      <Button
                        primary
                        onClick={this.doneEditing}
                        disabled={invalidElements}
                        className="doneButton"
                        label="Done"
                        size="small"
                      />
                    </div>
                  ) : (
                    <span className="promptOutput">
                      {
                        Object.keys(promptData).map((k) => {
                          const p = promptData[k];
                          return p.replaceable ? (
                            <button
                              key={k}
                              onClick={() => this.replaceMe(k, p)}
                              disabled={disableButtons[k] === true}
                              type="button"
                              title={`#${p.tag}`}
                            >
                              {p.text}
                            </button>
                          ) : (
                            <span key={k}>
                              {p.text}
                            </span>
                          )
                        })
                      }
                    </span>
                  )
              }
            </div>
            {
              !showEdit && (
                <div className="promptTools">
                  <button
                    type="button"
                    className="actionButton"
                    title="Edit Prompt"
                    onClick={() => this.setState({ showEdit: !showEdit })}
                  >
                    <EditIcon />
                  </button>
                  <CopyToClipboard className="copyButton actionButton" text={promptText}>
                    <button title="Copy to Clipboard" type="button"><ClipIcon /></button>
                  </CopyToClipboard>
                </div>
              )
            }
          </div>
          {
            !showEdit && (
              <div className="heroButtons">
                <Button
                  label="Generate New"
                  primary
                  size="large"
                  disabled={fetching}
                  onClick={() => this.getRandom()}
                  className="generateButton"
                />
              </div>
            )
          }
          {
            showHelp && (
              <div className="help">
                <p>
                  { this.helpText() }
                </p>
              </div>
            )
          }
          <div className="savedPrompts">
            {
              generatedPrompts.map(({ key, text }) => (
                <div key={key}>
                  <div className="promptText">{ text }</div>
                  <CopyToClipboard className="actionButton" text={text}>
                    <button title="Copy to Clipboard" type="button"><ClipIcon /></button>
                  </CopyToClipboard>
                </div>
              ))
            }
          </div>
        </Wrap>
        <MobileView>
          <AddToHomescreen
            onAddToHomescreenClick={this.handleAddToHomescreenClick}
            icon={homeScreenLogo}
          />
        </MobileView>
      </Page>
    );
  }
}
